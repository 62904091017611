body {
  margin: 0;
 /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: Lato !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Page */
.lock_top {
  background-color: #222;
}
hr {
  border-color: #AAAAAA;
}
/* Standard Dark: created by Alex 'mcmonkey' Goodwin */
/* Denizen Script Colors */
.script_comment_header{color:#FF0000;}
.script_comment_normal{color:#007700;}
.script_comment_code{color:#DD5500;}
.script_comment_todo{color:#FFFF00;font-weight:bold;}
.script_key{color:#1155FF;}
.script_key_inline{color:#1155FF;font-style:italic;}
.script_command{color:#DD99FF;font-style:italic;}
.script_quote_double{color:#77BBF5;}
.script_quote_single{color:#88DDFF;}
.script_tag{color:#AAAAAA;}
.script_tag_dot{color:#EEEEFF;}
.script_tag_param{color:#7777FF;}
.script_tag_param_bracket{color:#7777FF;}
.script_colon{color:#66CCFF;font-weight:bold;}
.script_bad_space{color:#FFFFFF;background-color:#440000;}
.script_normal{color:#FFFFFF;}
.script_def_name{color:#33CCFF;font-style:italic;}
/* Denizen Command Syntax Colors */
.syntax_command{color:#DD99FF;}
.syntax_required{color:#7777FF;font-weight:bold;}
.syntax_optional{color:#77BBF5;}
.syntax_default{color:#55DDBB;font-style:italic;}
.syntax_fillable{color:#AAAAAA;}
.syntax_list{color:#EEEEFF;}
.syntax_colon{color:#66CCFF;font-weight:bold;}

.td-doc-key {
    width: 150px;
}

.table {
    max-width: 1500px;
    border: 2px #606060 solid;
}

.status {
    min-height: 650px;
}

.jumbotron {
    padding: 2rem;
    background-color: #303030;
}

.whiteSpace {
    white-space: pre
}

/* use local google font due gpdr */
/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/public/fonts/lato-latin-ext-i400.woff2') format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
  /* latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/lato-latin-i400.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
  /* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/lato-latin-ext-n400.woff2) format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
  /* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/lato-latin-n400.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
  /* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/public/fonts/lato-latin-ext-n700.woff2) format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
  /* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/public/fonts/lato-latin-n700.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}